import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.lowb,
    earningToken: tokens.lowb,
    contractAddress: {
      97: '',
      56: '0x4b6080917a8333D5DB16345642D9899e9A870d9f',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10.4',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 9,
    stakingToken: tokens.lowb,
    earningToken: tokens.game,
    contractAddress: {
      97: '',
      56: '0xb49036c84665DD97aC50D91894a1CBfDdd52A971',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000173611111111',
    sortOrder: 2,
    isFinished: false,
  },
  {
    sousId: 3,
    stakingToken: tokens.lowb,
    earningToken: tokens.deposit,
    contractAddress: {
      97: '',
      56: '0x7F76026cF80Ea756E4a4E8FCa0A7C8977C600cF0',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0',
    sortOrder: 3,
    isFinished: false,
  }
  
]

export default pools
